import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import { Container } from "react-bootstrap"

// markup
const Sitemap = () => {
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(sort: "Sorting:asc") {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Topmenu
          Template
          RouterOff
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)

  // console.log(data, "Data")

  var menu_data = data.glstrapi.allMenus

  return (
    <>
      <Header />
      <Container>
        <div className="site-map-page">
          <ul className="mainSubmenu">
            {menu_data.map(menuitem => {
              return (
                <>
                  {menuitem.Topmenu === true && (
                    <>
                      <li className="main-menu">
                        {menuitem.Secondary_URL ? (
                          <Link
                            to={
                              menuitem.Label === "Services"
                                ? ""
                                : menuitem.Secondary_URL
                            }
                          >
                            {menuitem.Label}
                          </Link>
                        ) : (
                          <Link
                            to={
                              menuitem.Label === "Services"
                                ? "#"
                                : `/${menuitem.Primary_URL}`
                            }
                          >
                            {menuitem.Label}
                          </Link>
                        )}
                        <ul className="submenu">
                          {menu_data.map(submenu => {
                            return (
                              <>
                                {submenu.Main_Parent &&
                                  submenu.Main_Parent.Label == menuitem.Label &&
                                  submenu.Sub_Parent == null && (
                                    <>
                                      {isClient ? (
                                        <li>
                                          {submenu.Label === "Our Team" ? (
                                            <Link to={"/purpose/our-people/"}>
                                              {submenu.Label}
                                            </Link>
                                          ) : submenu.Label ===
                                            "Request Maintenance" ? (
                                            <Link
                                              to={
                                                "/property-services/rent/request-maintenance"
                                              }
                                            >
                                              {submenu.Label}
                                            </Link>
                                          ) : submenu.Label === "Blog" ? (
                                            <Link to={"/blog"}>
                                              {submenu.Label}
                                            </Link>
                                          ) : submenu.Label ===
                                            "Area Guides" ? (
                                            <Link to={"/area-guides"}>
                                              {submenu.Label}
                                            </Link>
                                          ) : (
                                            <Link
                                              to={`/${menuitem.Primary_URL}/${submenu.Primary_URL}`}
                                            >
                                              {submenu.Label}
                                            </Link>
                                          )}

                                          {/* <ul className="submenu2">
                                                                        {menu_data.map((submenu2) => {
                                                                            return <>
                                                                                {submenu2.Main_Parent != null && submenu2.Sub_Parent != null && submenu2.Main_Parent.Label === 'Services' && submenu2.Sub_Parent.Label === submenu.Label &&
                                                                                    <li><Link to={`/${submenu2.Main_Parent.Primary_URL}/${submenu2.Sub_Parent.Primary_URL}/${submenu2.Primary_URL}`}>{submenu2.Label}</Link></li>
                                                                                }
                                                                            </>
                                                                        })}
                                                                    </ul> */}
                                        </li>
                                      ) : null}
                                    </>
                                  )}
                              </>
                            )
                          })}
                          {menuitem.Label === "Search" && (
                            <>
                              <li>
                                <Link to={`/property/for-sale`}>
                                  Property for Sale
                                </Link>
                              </li>
                              <li>
                                <Link to={`/property/to-rent`}>
                                  Property to Rent
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </li>
                    </>
                  )}
                </>
              )
            })}
          </ul>
        </div>
      </Container>
      <Footer popularSearch="Popular_Search_Static_Common" />
    </>
  )
}

export default Sitemap
